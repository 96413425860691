import { Button, Form, Input, message, Spin } from "antd"
import cx from "classnames"
import firebase from "gatsby-plugin-firebase"
import React, { InputHTMLAttributes, useState } from "react"
import InputMask from "react-input-mask"
import { badgesForm, dataForm } from "../../mock/data"
import Badges from "../Badges"
import SelectCustom from "../SelectCustom"
import "./style.less"
import * as styles from "./styles.module.css"
import ReCAPTCHA from "react-google-recaptcha"

interface ContactProps {
  fieldName: string
}
const ContactForm = () => {
  const [form] = Form.useForm()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [mask, setMask] = useState('(99) 99999-9999')
  const [reason, setReason] = useState("")
  const [contact, setContact] = useState("")
  const [moment, setMoment] = useState("")
  const [msgNameValidation, setMsgNameValidation] = useState("O campo é obrigatório.")
  const [isLoading, setIsLoading] = useState(false)

  const onReset = () => {
    form.resetFields()
  }
  const handleSubmit = () => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("contact-form")
      .add({
        name: name,
        email: email,
        phone: phone,
        reason: reason,
        contact: contact,
        moment: moment,
        createdAt: firebase.firestore.Timestamp.now()
          .toDate()
          .toLocaleString("pt-br"),
      })
      .then(() => {
        onReset()
        setIsLoading(false);
        message.success({
          content: dataForm.submitMessageSucess,
          className: "custom-message",
        })
      })
      .catch((error) => {
        setIsLoading(false);
        message.error({
          content: dataForm.submitMessageError,
          className: "custom-message-error",
        })
      })
  }
  const handleChange = ({ fieldName }: ContactProps) => {
    const field = form.getFieldValue(fieldName)

    setName(field.name)
    setEmail(field.email)
    setPhone(field.phone)
  }

  const handleSelectReason = (e: any) => {
    setReason(e.target.value)
  }

  const handleSelectContact = (e: any) => {
    setContact(e.target.value)
  }

  const handleSelectMoment = (e: any) => {
    setMoment(e.target.value)
  }

  const containerClass = cx("mx-auto", styles.container)
  const containerFormClass = cx(
    "mx-auto bg-blackPearl border-solid border-2 border-aquamarine lg:w-7/12 md:p-14 sm:p-5 xs:p-5 contact-form",
    styles.containerForm
  )
  const buttonClass = cx(
    "flex justify-center pt-3")

  function validateName(e: any) {
    let completeName = e.target.value;    
    
    if(completeName.split(' ')[0].length < 3){
      setMsgNameValidation("Insira um nome com mais de dois caracteres")
      form.setFieldsValue({ 'name': '' })
      form.validateFields(['name'])
    }else{
      setMsgNameValidation("O campo é obrigatório.")
    }
  }

  function validateEmail(e: any) {
    let email = e.target.value;
    if (email === "" || email === " ") {
      form.setFieldsValue({ 'email': '' })
      form.validateFields(['email'])
    }
  }

  function validateOnlyLetters(text: any) {
    let textOnlyLetters = text.target.value;
    textOnlyLetters = textOnlyLetters.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z ])/g, '');

    form.setFieldsValue({ name: textOnlyLetters });
  }

  function onChangeInput(e: any) {
    setMask(e.target.value.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999')
  }

  function validatePhoneNumber(e: any) {
    if (e.target.value.length < 14) {
      form.setFieldsValue({ phone: '' })
      form.validateFields(['phone'])
    }
  }

  return (
    <div className={containerClass} id="contato">
      <div className={containerFormClass}>
        <div className="flex">
          {badgesForm.map((item, index) => (
            <div key={index}>
              <Badges text={item.text} icon={item.icon} index={index} />
            </div>
          ))}
        </div>
        <div className={"sm:text-[36px] md:text-[40px] text-white font-bold leading-10 mt-3 mb-10"}>
          <span
            className={styles.titleClass}
            dangerouslySetInnerHTML={{ __html: dataForm.title }} 
          />
        </div>
        {isLoading ? (
          <div className="text-center">
            <Spin size="large" />
          </div>
        ) : (
          <Form
            name="contato"
            form={form}
            onFinish={handleSubmit}
            onChange={handleChange}
            validateMessages={{
              required: dataForm.requiredField,
              types: {
                email: dataForm.invalidEmail,
              },
            }}
          >
            <Form.Item
              {...form.getFieldValue("name")}
              name="name"
              rules={[{
                message: msgNameValidation,
                required: true
              }]}
            >
              <Input required placeholder={dataForm.name} onBlur={validateName} onChange={validateOnlyLetters} />
            </Form.Item>

            <Form.Item name="email" rules={[{ type: "email", required: true }]}>
              <Input type="email" required placeholder={dataForm.email} onBlur={validateEmail}/>
            </Form.Item>
            <Form.Item
              required
              name="phone"
              rules={[{ required: true, message: dataForm.invalidPhone }]}
            >
              <InputMask
                maskChar=""
                mask={mask}
                onChange={onChangeInput}
                onBlur={validatePhoneNumber}
              >
                {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
                  <Input
                    {...inputProps}
                    placeholder={dataForm.phone}
                    className="custom-input-phone"
                  />
                )}
              </InputMask>
            </Form.Item>

          <Form.Item required name="reason" rules={[{required: true }]}>
            <SelectCustom key={"reason"} value={reason} placeholder={dataForm.reason} onChange={handleSelectReason} options={dataForm.reasonOptions}/>
          </Form.Item>

          <Form.Item name="moment" rules={[{required: true }]}>
            <SelectCustom key={"moment"} value={moment} placeholder={dataForm.moment} onChange={handleSelectMoment} options={dataForm.momentOptions}/>
          </Form.Item>
          
          <Form.Item name="contact" rules={[{required: true }]}>
            <SelectCustom key={"contact"} value={contact} placeholder={dataForm.contact} onChange={handleSelectContact} options={dataForm.contactOptions}/>
          </Form.Item>
          
            <ReCAPTCHA 
              sitekey={process.env.RECAPTCHA_KEY}
              id="recaptcha"
            />

            <div className={buttonClass}>
              <Button className="custom-button" id ="unlockBtn" htmlType="submit">
                {dataForm.textButton}
              </Button>
            </div>

          </Form>
        )
      }        
      </div>
    </div>
  )

}
export default ContactForm
